@use "../../../tokens";

.announcementBtn {
  all: unset;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: tokens.$color-violet-70;
  position: relative;

  .badge {
    position: absolute;
    top: -5px;
    right: -4px;
    background-color: tokens.$color-green-50;
    color: tokens.$color-black;
    font: tokens.$text-body-2xs;
    font-weight: 700;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.announcementsWrapper {
  width: tokens.$content-xs;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-sm;
  box-shadow: tokens.$box-shadow-lg;
  border-radius: tokens.$border-radius-md;
  padding: tokens.$spacing-md 0;
  position: relative;

  @media screen and (min-width: tokens.$screen-md) {
    width: tokens.$content-sm;
  }

  &::before {
    $tooltip-size: 12px;
    background-color: white;
    content: "";
    height: $tooltip-size;
    position: absolute;
    right: 100px; // Vertically align chevron with bell icon on mobile
    top: 0;
    transform: translateY(-50%) rotate(45deg);
    width: $tooltip-size;

    @media screen and (min-width: tokens.$screen-md) {
      right: 125px; // Vertically align chevron with bell icon on desktop
    }
  }

  .announcementsTabList {
    display: flex;
    flex-direction: row;
    border-radius: tokens.$border-radius-xl;
    background-color: tokens.$color-grey-10;
    align-items: center;
    padding: tokens.$spacing-xs;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;

    button {
      all: unset;
      cursor: pointer;
      border-radius: tokens.$border-radius-xl;
      padding: tokens.$spacing-xs tokens.$spacing-xl;
      align-items: center;
      text-align: center;
      justify-content: center;
      display: flex;
      color: tokens.$color-grey-50;

      &.active {
        color: tokens.$color-purple-80;
        background-color: tokens.$color-white;
        font-weight: 600;
      }
    }
  }

  .announcementsContainer {
    padding: 0 tokens.$spacing-sm;
    max-height: 500px; // Limit container height and enable scrolling if content overflows
    overflow-y: auto;

    .announcementItem {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: tokens.$spacing-md;
      align-content: center;
      align-items: center;
      border-radius: tokens.$border-radius-md;
      padding: tokens.$spacing-sm tokens.$spacing-sm;

      &:hover {
        background-color: tokens.$color-purple-10;
      }

      .smallImg {
        width: 70px;
        height: 70px;
        border-radius: tokens.$border-radius-md;
      }

      dl {
        display: flex;
        flex-direction: column;

        dt {
          font-weight: 600;
          padding-bottom: tokens.$spacing-xs;
        }
      }
    }

    .emptyState {
      display: flex;
      flex-direction: column;
      gap: tokens.$spacing-md;
      padding-top: tokens.$spacing-xl;
      padding-bottom: tokens.$spacing-lg;
      padding-left: tokens.$spacing-md;
      padding-right: tokens.$spacing-md;
      text-align: center;

      justify-content: center;
      align-items: center;

      dl {
        display: flex;
        flex-direction: column;
        gap: tokens.$spacing-sm;

        dt {
          font: tokens.$text-title-3xs;
        }
      }

      img {
        width: 100%;
      }
    }

    .announcementWrapperOpen {
      padding: tokens.$spacing-md;

      .announcementItemOpen {
        display: flex;
        flex-direction: column;
        gap: tokens.$spacing-sm;

        dt {
          font: tokens.$text-title-3xs;
        }
      }

      .announcementCta {
        margin-top: tokens.$spacing-sm;
      }
    }

    .backBtn {
      all: unset;
      font-weight: 500;
      color: tokens.$color-purple-80;
      border-top: 1px solid tokens.$color-grey-20;
      padding-top: tokens.$spacing-md;
      width: 100%;
      text-align: left;

      span {
        padding-left: tokens.$spacing-md;
        cursor: pointer;
      }
    }

    .clearAllBtn {
      all: unset;
      margin-top: tokens.$spacing-sm;
      font-weight: 500;
      color: tokens.$color-purple-80;
      border-top: 1px solid tokens.$color-grey-20;
      padding-top: tokens.$spacing-md;
      width: 100%;
      text-align: right;

      span {
        padding-right: tokens.$spacing-md;
        cursor: pointer;
      }
    }
  }

  .horizontalLine {
    border: none;
    height: 1px;
    background-color: tokens.$color-grey-20;
  }

  .bigImg {
    width: 100%;
    height: auto;
  }

  .dismissButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: tokens.$spacing-md;
    color: tokens.$color-grey-40;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      color: tokens.$color-grey-30;
    }
  }
}
